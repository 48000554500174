import { v4 } from 'uuid'

import { store } from '@/store/store'
import { ADD_TOAST, SET_SHOW } from '@/store/toasts/actions/types'

const createToast = (message: string) => {
  const key = v4()
  const toast = {
    key,
    show: true,
    text: message,
  }
  store.dispatch({ type: ADD_TOAST, payload: toast })
}

const hideToast = (key: string) => {
  store.dispatch({ type: SET_SHOW, payload: { key, show: false } })
}

const toastService = {
  createToast,
  hideToast,
}

export default toastService
