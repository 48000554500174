import { useEffect, useState } from 'react'

import { useAppSelector } from '@/store/hooks'
import { PatreonObjectType } from '@/store/user/initialState'

const patronTier = (patreon: PatreonObjectType | undefined, isAdmin: boolean) => {
  if (isAdmin) return 'rare'

  if (patreon?.patron_status === 'active_patron') {
    if (patreon?.currently_entitled_amount_cents >= 500) return 'rare'
    else if (patreon?.currently_entitled_amount_cents >= 200) return 'uncommon'
    return undefined
  }

  return undefined
}

const usePatreonTier = () => {
  const patreon = useAppSelector((state) => state.user.patreon)
  const isAdmin = useAppSelector((state) => state.user.isAdmin)
  const [tier, setTier] = useState(patronTier(patreon, !!isAdmin))

  useEffect(() => {
    setTier(patronTier(patreon, !!isAdmin))
  }, [patreon, isAdmin])

  return tier
}

export default usePatreonTier
