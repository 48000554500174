import { useRouter } from 'next/router'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'

const GoogleAnalytics = () => {
  const router = useRouter()

  useEffect(() => {
    // React GA4
    ReactGA.initialize(process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID as string)

    const handleRouteChange = (url: string) => ReactGA.send({ hitType: 'pageview', page: url })

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => router.events.off('routeChangeComplete', handleRouteChange)
  }, [])

  return null
}

export default GoogleAnalytics
