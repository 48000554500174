// @ts-nocheck

import classNames from 'classnames'

import Icon from '@/components/Icon/Icon'
import { addCard } from '@/store/clipboard/actions/addCard'
import { promoteToCommander } from '@/store/clipboard/actions/promoteToCommander'
import { DEMOTE_COMMANDER, OPEN_MOBILE_IMAGE, REMOVE_CARD, SET_VIEWER_IMAGE } from '@/store/clipboard/actions/types'
import { useActionless } from '@/store/hooks'
import { CardType } from '@/types/card'

import styles from './ClipboardItem.module.scss'

type Props = {
  card: CardType
  legal: boolean
  type: 'card' | 'rec' | 'commander'
  recsIndex?: number
}

const ClipboardItem = ({ card, legal, type, recsIndex }: Props) => {
  const [removeCard, demoteCommander, setViewerImage, openMobileImage] = useActionless(
    REMOVE_CARD,
    DEMOTE_COMMANDER,
    SET_VIEWER_IMAGE,
    OPEN_MOBILE_IMAGE,
  )

  const handleShow = () => {
    setViewerImage(card.image_uri as string)
    openMobileImage()
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.item, {
          [styles.rec]: type === 'rec',
          [styles.illegal]: !legal,
        })}
        onClick={handleShow}
      >
        {card.name}
      </div>
      <div className={classNames(styles.buttons, 'd-flex d-md-none')}>
        {type === 'card' && (card.legal_commander || card.subtypes?.includes('Background')) && (
          <span onClick={() => promoteToCommander(card)}>
            <Icon className={styles.commanderButton} icon='crown' size='xl' />
          </span>
        )}
        {type === 'commander' && (
          <span onClick={() => demoteCommander(card.url as string)}>
            <Icon className={styles.removeButton} icon='arrowDown' size='xl' />
          </span>
        )}
        <span onClick={() => window.open(`/${type === 'rec' ? 'card' : type}s/${card.url}`, '_blank')}>
          <Icon className={styles.link} icon='link' size='xl' />
        </span>
        {type === 'rec' ? (
          <span onClick={() => addCard(card, recsIndex)}>
            <Icon className={styles.addButton} icon='check' size='xl' />
          </span>
        ) : (
          <span onClick={() => removeCard(card)}>
            <Icon className={styles.removeButton} icon='times' size='xl' />
          </span>
        )}
      </div>
      <div className={classNames(styles.buttons, 'd-none d-md-flex')}>
        {type === 'card' && (card.legal_commander || card.subtypes?.includes('Background')) && (
          <span onClick={() => promoteToCommander(card)}>
            <Icon className={styles.commanderButton} icon='crown' />
          </span>
        )}
        {type === 'commander' && (
          <span onClick={() => demoteCommander(card.url as string)}>
            <Icon className={styles.removeButton} icon='arrowDown' size='lg' />
          </span>
        )}
        <span onClick={() => window.open(`/${type === 'rec' ? 'card' : type}s/${card.url}`, '_blank')}>
          <Icon className={styles.link} icon='link' />
        </span>
        {type === 'rec' ? (
          <span onClick={() => addCard(card, recsIndex)}>
            <Icon className={styles.addButton} icon='check' />
          </span>
        ) : (
          <span onClick={() => removeCard(card)}>
            <Icon className={styles.removeButton} icon='times' size='xl' />
          </span>
        )}
      </div>
    </div>
  )
}

export default ClipboardItem
