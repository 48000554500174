import { v4 } from 'uuid'

import { CountFilterRowType, NameFilterRowType } from '@/types/filters'

export const getInitialCountRow = (): CountFilterRowType => ({
  key: v4(),
  number: '',
  op: 'gt',
  type: 'c',
})

export const getInitialNameRow = (): NameFilterRowType => ({
  key: v4(),
  name: [],
  preposition: 'in',
})

export type FiltersStateType = {
  countFilters: CountFilterRowType[]
  countForm: CountFilterRowType
  error: string
  nameFilters: NameFilterRowType[]
  nameForm: NameFilterRowType
}

export const initialFiltersState: FiltersStateType = {
  countFilters: [],
  countForm: getInitialCountRow(),
  error: '',
  nameFilters: [],
  nameForm: getInitialNameRow(),
}
