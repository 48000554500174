import { FilterType } from '@/types/additionalFilters'
import { RecCardType } from '@/types/card'
import { defaultGroupedBy, defaultSortedBy, groupByModes } from '@/utils/constants'

export type CardListType = {
  cardviews: RecCardType[]
  header: string
  tag: string
  href?: string
  more?: string
  url?: string
}

export type CardListsStateType = {
  cardLists: CardListType[]
  filters: FilterType[]
  groupedBy: (typeof groupByModes)[number]['key']
  initCardLists: CardListType[]
  loading: boolean
  persist: boolean
  sortedBy: string
  updatedAt: number
}

export const initialCardListsState: CardListsStateType = {
  cardLists: [],
  filters: [],
  groupedBy: defaultGroupedBy,
  initCardLists: [],
  loading: false,
  persist: false,
  sortedBy: defaultSortedBy,
  updatedAt: 0,
}
