import { store } from '@/store/store'

import requestService from './request.service'

const getClipboardRecs = async () => {
  const commanders = store.getState().clipboard.commanders

  if (commanders.length === 0) {
    throw new Error('Select a commander by clicking a crown icon')
  }

  const res = await requestService.post('/api/recs', {
    cards: Object.keys(store.getState().clipboard.cards),
    commanders,
    full_card_data: true,
  })
  const json = await res.json()

  if (json.errors) throw new Error(json.errors[0])

  return json
}

const RecsService = {
  getClipboardRecs,
}

export default RecsService
