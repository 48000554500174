import { store } from '@/store/store'
import { CardType } from '@/types/card'

import { ADD_CARD, POP_REC, SET_VIEWER_IMAGE } from './types'

export type AddCardActionType = {
  type: typeof ADD_CARD
  payload: CardType[] // url
}

export const addCard = (card: CardType | CardType[], recsIndex?: number) => {
  const dispatch = store.dispatch
  const cardArray = Array.isArray(card) ? card : [card]
  if (recsIndex) {
    dispatch({ payload: recsIndex, type: POP_REC })
  }
  const cardArrayWithPartners: CardType[] = []
  cardArray.forEach((card) => {
    if (card.is_partner) {
      card.cards?.map((card) => cardArrayWithPartners.push(card as any))
    } else {
      cardArrayWithPartners.push(card)
    }
  })
  dispatch({ type: ADD_CARD, payload: cardArrayWithPartners })
  dispatch({ type: SET_VIEWER_IMAGE, payload: cardArray[0].image_uris[0] })
}
