import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { Cookies } from 'react-cookie'
import { Dispatch, Store } from 'redux'
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import { persistStore } from 'redux-persist'

import { initialCardListsState } from '@/store/cardlists/initialState'
import { getUserInitialState } from '@/store/user/initialState'

import { ActionTypes } from './actions'
import reducer, { RootState } from './reducers'

let store: AppStore

export const makeStore = (cookies?: string) => {
  const initialCookies = !cookies ? undefined : new Cookies(cookies)

  const cardlistsState = {
    ...initialCardListsState,
    filters: initialCookies?.get('filters') || initialCardListsState.filters,
    persist: !!(initialCookies?.get('filters') || initialCookies?.get('groupedBy') || initialCookies?.get('sortedBy')),
    groupedBy: initialCookies?.get('groupedBy') || initialCardListsState.groupedBy,
    sortedBy: initialCookies?.get('sortedBy') || initialCardListsState.sortedBy,
  }

  const preloadedState = {
    cardlists: cardlistsState,
    user: getUserInitialState(initialCookies),
  }

  store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    preloadedState,
    reducer,
  })

  const persistor = persistStore(store)

  return { persistor, store }
}

export type AppStore = Store<RootState, ActionTypes> & { dispatch: Dispatch }

export { store }
