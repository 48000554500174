import { useEffect } from 'react'

import { useAppSelector } from '@/store/hooks'
import { fetchRegion } from '@/store/user/actions/fetchRegion'

const RegionDetector = () => {
  const region = useAppSelector((state) => state?.user.region)

  useEffect(() => {
    if (!region) {
      fetchRegion()
    }
  }, [])

  return null
}

export default RegionDetector
