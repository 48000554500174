import {
  confirmPasswordReset,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  UserCredential,
} from '@firebase/auth'
import { doc, getDoc, setDoc } from '@firebase/firestore'

import requestService from '@/services/request.service'
import { store } from '@/store/store'
import { SET_USER_STATE } from '@/store/user/actions/types'
import { FirestoreProfileType } from '@/types/firestore'

import { auth, firestore } from './firebase.service'

const confirmForgotPassword = async (code: string, newPassword: string) => confirmPasswordReset(auth, code, newPassword)

const deleteUser = async () => await (await requestService.get('/api/users/delete')).json()

const forgotPassword = async (email: string) => sendPasswordResetEmail(auth, email)

const login = async (email: string, password: string) => {
  let user: UserCredential
  try {
    user = await signInWithEmailAndPassword(auth, email.trim(), password)
  } catch (e: any) {
    throw new Error(e.message.replace('Firebase: ', ''))
  }

  const docRef = doc(firestore, 'users', user.user.uid)
  const data = (await getDoc(docRef)).data() as FirestoreProfileType

  store.dispatch({
    type: SET_USER_STATE,
    payload: {
      uid: user.user.uid,
      ...data,
    },
  })

  return user
}

const register = async ({
  consents,
  email,
  password,
  username,
}: {
  consents: { newsletter: boolean }
  email: string
  password: string
  username: string
}) =>
  await (
    await requestService.post(
      '/api/users/register',
      {
        email,
        consents,
        password,
        username,
      },
      {},
      false,
    )
  ).json()

const subscribeToNewsletter = async () => await (await requestService.get('/api/users/newsletter')).json()

const update = async (profile: Partial<FirestoreProfileType>) => {
  const uid = store.getState().user.uid
  if (!uid) return
  const docRef = doc(firestore, 'users', uid)
  await setDoc(docRef, profile, { merge: true })
}

const profileService = {
  confirmForgotPassword,
  deleteUser,
  forgotPassword,
  login,
  register,
  subscribeToNewsletter,
  update,
}

export default profileService
