import { Component } from 'react'
import ReactGA from 'react-ga4'

import ErrorAlert from '@/components/ErrorAlert/ErrorAlert'

type Props = {
  hasError: boolean
  error?: Error
}

class ErrorBoundary extends Component<any, Props> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { error, hasError: true }
  }

  componentDidCatch(error: Error, _errorInfo: any) {
    ReactGA.event('exception', {
      description: error.message,
      fatal: true,
    })
  }

  render() {
    if (this.state.hasError) {
      const stack = this.state.error?.stack || this.state.error?.message
      return <ErrorAlert stack={stack} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
