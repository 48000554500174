import { MiscActions } from './actions'
import * as Misc from './actions/types'
import { initialMiscState } from './initialState'

const misc = (state = initialMiscState, action: MiscActions) => {
  switch (action.type) {
    case Misc.FETCHING_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default misc
