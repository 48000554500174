import { CardType } from '@/types/card'

export type ClipboardStateType = {
  cards: Record<string, null>
  commanders: string[]
  error?: string
  isLoadingSCG: boolean
  isOpen: boolean
  loadedCards: Record<string, CardType>
  mobileImageIsOpen: boolean
  prevStates: ClipboardStateType[]
  recs: CardType[]
  viewerImage: string | null
}

export const initialClipboardState: ClipboardStateType = {
  cards: {},
  commanders: [],
  error: '',
  isLoadingSCG: false,
  isOpen: false,
  loadedCards: {},
  mobileImageIsOpen: false,
  prevStates: [],
  recs: [],
  viewerImage: null,
}
