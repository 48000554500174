// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { initializeFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBApUeFUbJlInWIWdXho4DK-lgYKE2AhkE',
  authDomain: 'edhrec-1563844611919.firebaseapp.com',
  databaseURL: 'https://edhrec-1563844611919.firebaseio.com',
  projectId: 'edhrec-1563844611919',
  storageBucket: 'edhrec-1563844611919.appspot.com',
  messagingSenderId: '808648763959',
  appId: '1:808648763959:web:0cf20afb11ac405831edb2',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
export const firestore = initializeFirestore(app, {})

export default app
