import { ClipboardActions } from './actions'
import * as Clipboard from './actions/types'
import { ClipboardStateType, initialClipboardState } from './initialState'

const appendPrevState = ({ cards, commanders, prevStates }: ClipboardStateType) =>
  [...prevStates, { cards, commanders }].slice(-10)

const reducer = (state = initialClipboardState, action: ClipboardActions) => {
  switch (action.type) {
    case Clipboard.ADD_CARD: {
      const cards = { ...state.cards }
      const loadedCards = { ...state.loadedCards }
      action.payload.forEach((card) => {
        if (card.sanitized_wo && !state.commanders.includes(card.sanitized_wo)) {
          cards[card.sanitized_wo] = null
          loadedCards[card.sanitized_wo] = card
        } else if (card.url && !state.commanders.includes(card.url)) {
          cards[card.url] = null
          loadedCards[card.url] = card
        }
      })
      return {
        ...state,
        cards,
        loadedCards,
        error: '',
        prevStates: appendPrevState(state),
      }
    }
    case Clipboard.APPEND_COMMANDER: {
      const { [action.payload]: _, ...cards } = state.cards
      const commanders = [...state.commanders, action.payload].sort()
      return { ...state, cards, commanders, error: '', prevStates: appendPrevState(state) }
    }
    case Clipboard.CLEAR_CLIPBOARD:
      return {
        ...initialClipboardState,
        isOpen: state.isOpen,
        loadedCards: state.loadedCards,
        prevStates: appendPrevState(state),
      }
    case Clipboard.CLOSE_MOBILE_IMAGE:
      return {
        ...state,
        mobileImageIsOpen: false,
      }
    case Clipboard.DEMOTE_COMMANDER:
      return {
        ...state,
        cards: { ...state.cards, [action.payload]: null },
        commanders: state.commanders.filter((url) => url !== action.payload),
        error: '',
        prevStates: appendPrevState(state),
      }
    case Clipboard.ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case Clipboard.OPEN_MOBILE_IMAGE:
      return {
        ...state,
        mobileImageIsOpen: true,
      }
    case Clipboard.POP_REC:
      return {
        ...state,
        error: '',
        recs: [...state.recs.slice(0, action.payload), ...state.recs.slice(action.payload + 1)],
      }
    case Clipboard.REMOVE_CARD: {
      const cardArray = Array.isArray(action.payload) ? action.payload : [action.payload]
      const cards = { ...state.cards }
      const loadedCards = { ...state.loadedCards }
      let commanders = [...state.commanders]
      cardArray.forEach((card) => {
        const faces = card.cards || [card]
        faces.forEach((face) => {
          delete cards[face.sanitized_wo || face.url || '']
          delete loadedCards[face.sanitized_wo || face.url || '']
          commanders = state.commanders.filter((x) => x !== face.sanitized_wo && x !== face.url)
        })
      })
      return { ...state, cards, commanders, loadedCards, error: '', prevStates: appendPrevState(state) }
    }
    case Clipboard.REPLACE_COMMANDER: {
      const { [action.payload]: _, ...cards } = state.cards
      state.commanders.map((commander) => (cards[commander] = null))
      const commanders = [action.payload]
      return { ...state, cards, commanders, error: '', prevStates: appendPrevState(state) }
    }
    case Clipboard.SET_VIEWER_IMAGE:
      return {
        ...state,
        viewerImage: action.payload,
      }
    case Clipboard.TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
        mobileImageIsOpen: false,
      }
    case Clipboard.UNDO: {
      const { prevStates } = state
      const { cards, commanders } = prevStates[prevStates.length - 1]
      return {
        ...state,
        cards,
        commanders,
        error: '',
        prevStates: prevStates.slice(0, prevStates.length - 1),
      }
    }
    case Clipboard.SET_CLIPBOARD_STATE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default reducer
