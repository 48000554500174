export const ADD_CARD = 'clipboard/ADD_CARD'
export const APPEND_COMMANDER = 'clipboard/APPEND_COMMANDER'
export const CLEAR_CLIPBOARD = 'clipboard/CLEAR_CLIPBOARD'
export const CLOSE_MOBILE_IMAGE = 'clipboard/CLOSE_MOBILE_IMAGE'
export const DEMOTE_COMMANDER = 'clipboard/DEMOTE_COMMANDER'
export const ERROR = 'clipboard/ERROR'
export const OPEN_MOBILE_IMAGE = 'clipboard/OPEN_MOBILE_IMAGE'
export const POP_REC = 'clipboard/POP_REC'
export const RECS = 'clipboard/RECS'
export const RECS_ERROR = 'clipboard/RECS_ERROR'
export const RECS_SUCCESS = 'clipboard/RECS_SUCCESS'
export const REPLACE_COMMANDER = 'clipboard/REPLACE_COMMANDER'
export const REMOVE_CARD = 'clipboard/REMOVE_CARD'
export const SET_CLIPBOARD_STATE = 'clipboard/SET_CLIPBOARD_STATE'
export const SET_VIEWER_IMAGE = 'clipboard/SET_VIEWER_IMAGE'
export const TOGGLE = 'clipboard/TOGGLE'
export const UNDO = 'clipboard/UNDO'
