export type SetType = {
  code: string
  icon_svg_uri: string
  name: string
}

export type TagType = {
  count: number
  name: string
  slug: string
}

export type TribeType = string

export type MiscStateType = {
  sets: SetType[]
  tags: TagType[]
  tcgplayerKickback?: {
    end: Date
    percent: number
    start: Date
  }
  tcgplayerSale: number
}

export const initialMiscState: MiscStateType = { sets: [], tags: [], tcgplayerSale: 0 }
