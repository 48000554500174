import { store } from '@/store/store'
import { PriceMapType } from '@/types/card'

import { initialPriceSources } from '../initialState'

import { SET_USER_STATE } from './types'

export const fetchRegion = async () => {
  const dispatch = store.dispatch
  const state = store.getState()

  try {
    const res = await fetch('https://edhrec.com/api/geo')
    const json = await res.json()
    const region = json['country-code']

    let priceSources = state.user.priceSources
    if (state.user.region !== region && state.user.priceSources[0] !== initialPriceSources[2]) {
      if (region == 'CA') {
        priceSources = ['face2face', state.user.priceSources[0], state.user.priceSources[1]] as Array<
          keyof PriceMapType
        >
      } else if (region === 'MX') {
        priceSources = ['tcgl', state.user.priceSources[0], state.user.priceSources[1]] as Array<keyof PriceMapType>
      }
    }

    dispatch({
      payload: {
        priceSources,
        region: json['country-code'],
      },
      type: SET_USER_STATE,
    })
  } catch (err) {
    console.error(err)
  }
}
