import { ToastsActions } from './actions'
import * as Toasts from './actions/types'
import { initialToastState } from './initialState'

const toasts = (state = initialToastState, action: ToastsActions) => {
  switch (action.type) {
    case Toasts.ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      }
    case Toasts.SET_SHOW: {
      const i = state.toasts.findIndex((toast) => toast.key === action.payload.key)
      if (i === -1) return state
      return {
        ...state,
        toasts: [
          ...state.toasts.slice(0, i),
          { ...state.toasts[i], show: action.payload.show },
          ...state.toasts.slice(i + 1),
        ],
      }
    }
    default:
      return state
  }
}

export default toasts
