// Not totally typesafe if something in local storage doesn't match it's type on read
const get = <T = any>(key: string): T | null => {
  if (typeof document === 'undefined') return null
  if (!localStorage) return null

  const storedValue: unknown = localStorage.getItem(key)

  return storedValue ? (storedValue as T) : null
}

// Not totally typesafe if something in local storage doesn't match its type on read
const getJSON = <T = any>(key: string): T | null => {
  if (typeof document === 'undefined') return null
  if (!localStorage) return null

  const value = localStorage.getItem(key)

  if (!value) return null

  try {
    return JSON.parse(value)
  } catch (err) {
    console.error(err)
    return null
  }
}

const remove = (key: string) => {
  if (typeof document === 'undefined') return
  if (!localStorage) return

  localStorage.removeItem(key)
}

const set = (key: string, value: string) => {
  if (typeof document === 'undefined') return
  if (!localStorage) return

  localStorage.setItem(key, value)
}

const setJSON = (key: string, obj: any) => {
  if (typeof document === 'undefined') return
  if (!localStorage) return

  const value = JSON.stringify(obj)

  localStorage.setItem(key, value)
}

const localStorageService = {
  get,
  getJSON,
  remove,
  set,
  setJSON,
}

export default localStorageService
