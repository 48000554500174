import { CombinedState, combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'

import { CardListsStateType } from './cardlists/initialState'
import cardlists from './cardlists/reducer'
import { ClipboardStateType } from './clipboard/initialState'
import clipboard from './clipboard/reducer'
import { FiltersStateType } from './filters/initialState'
import filters from './filters/reducer'
import { MiscStateType } from './misc/initialState'
import misc from './misc/reducer'
import { persistConfig } from './persistor'
import { ToastStateType } from './toasts/initialState'
import toasts from './toasts/reducer'
import { UserStateType } from './user/initialState'
import user from './user/reducer'

// Once we remove all the flow we can use ReturnType<typeof reducers> instead
export type RootState = CombinedState<{
  cardlists: CardListsStateType
  clipboard: ClipboardStateType
  filters: FiltersStateType
  misc: MiscStateType
  toasts: ToastStateType
  user: UserStateType
}>

export const rootReducer = combineReducers({
  cardlists,
  clipboard,
  filters,
  misc,
  toasts,
  user,
})

// Export default reducer
const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer)
export default persistedReducer
export const selectUser = (state: RootState): UserStateType => state.user
