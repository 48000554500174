import Link from 'next/link'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'

import profileService from '@/services/profile.service'
import { useActionless, useAppSelector } from '@/store/hooks'
import { SET_USER_STATE } from '@/store/user/actions/types'
import { PriceMapType } from '@/types/card'

import DarkModeSlider from './DarkModeSlider/DarkModeSlider'

type Props = {
  handleClose: () => void
  show: boolean
}

const Settings = ({ handleClose, show }: Props) => {
  const userUid = useAppSelector((state) => state.user.uid)
  const priceSources = useAppSelector((state) => state.user.priceSources)
  const namesUnderCards = useAppSelector((state) => state.user.namesUnderCards)
  const saltLevel = useAppSelector((state) => state.user.saltLevel)
  const [setUserState] = useActionless(SET_USER_STATE)
  const handlePriceSelect = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>,
    i: number,
  ) => {
    const newPriceSources = [
      ...priceSources.slice(0, i),
      e.target.value,
      ...priceSources.slice(i + 1),
    ] as (keyof PriceMapType)[]
    profileService.update({ priceSources: newPriceSources }).then(() => setUserState({ priceSources: newPriceSources }))
  }

  const handleNamesUnderCards = () => {
    const newNamesUnderCards = !namesUnderCards
    profileService
      .update({ namesUnderCards: newNamesUnderCards })
      .then(() => setUserState({ namesUnderCards: newNamesUnderCards }))
  }

  const handleSaltLevelChange = (i: number) => {
    profileService.update({ saltLevel: i }).then(() => setUserState({ saltLevel: i }))
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className='d-grid gap-3 pb-3'>
          <Form.Group as={Row}>
            <Form.Label column xs='5'>
              Dark Mode
            </Form.Label>
            <Col xs='7'>
              <div className='align-items-center d-flex h-100'>
                <DarkModeSlider />
              </div>
            </Col>
          </Form.Group>
          {userUid ? (
            <>
              {priceSources?.map((priceSource, i) => (
                <Form.Group as={Row} key={i}>
                  <Form.Label column xs='5'>{`Price Source ${i + 1}`}</Form.Label>
                  <Col xs='7'>
                    <Form.Control as='select' onChange={(e) => handlePriceSelect(e, i)} value={priceSource}>
                      <option value='cardkingdom'>Card Kingdom</option>
                      <option value='tcgplayer'>TCGplayer</option>
                      <option value='scg'>Star City Games</option>
                      <option value='manapool'>Mana Pool</option>
                      <option value='face2face'>Face to Face (CA)</option>
                      <option value='cardmarket'>Cardmarket (EU)</option>
                      <option value='tcgl'>TCG Land (MEX)</option>
                      <option value='cardhoarder'>Cardhoarder (MTGO)</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              ))}
              <Form.Group as={Row}>
                <Form.Label column xs='5'>
                  Names Under Cards
                </Form.Label>
                <Col xs='7'>
                  <div className='align-items-center d-flex h-100'>
                    <Form.Check checked={namesUnderCards} onClick={handleNamesUnderCards} type='checkbox' />
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column xs='5'>
                  Show Salt
                </Form.Label>
                <Col xs='7'>
                  <ButtonGroup>
                    {['Never', 'High Salt', 'Always'].map((label, i) => (
                      <Button
                        key={label}
                        onClick={() => handleSaltLevelChange(i)}
                        variant={saltLevel === i ? 'primary' : 'secondary'}
                      >
                        {label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Col>
              </Form.Group>
            </>
          ) : (
            <div className='d-flex align-items-center'>
              <Link href='/signin'>
                <Button onClick={handleClose}>Sign in</Button>
              </Link>
              <span className='ms-2'>to view more settings</span>
            </div>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default Settings
