type Props = {
  ariaLabel?: string
  children?: React.ReactNode
  className?: string
  dangerouslySetInnerHTML?: { __html: string }
  disableUtm?: boolean
  title?: string
  url: string | undefined
  utm_medium?: string
  utm_source?: string
}

const OutboundLink = ({
  ariaLabel,
  children,
  className,
  disableUtm,
  dangerouslySetInnerHTML,
  title,
  url,
  utm_medium,
  utm_source,
}: Props) => {
  let href = url
  if (!disableUtm) {
    href = href?.replace(/^((?!https?:\/\/).)/, 'https://$1')
    try {
      const parsedUrl = new URL(href || '')
      if (!parsedUrl.searchParams.has('utm_source')) parsedUrl.searchParams.set('utm_source', 'edhrec')
      if (utm_medium) parsedUrl.searchParams.set('utm_medium', utm_medium)
      if (utm_source) parsedUrl.searchParams.set('utm_source', utm_source)
      href = parsedUrl.href
    } catch (error) {
      console.warn(`Exception in OutboundLink. url=${url} error=${error} href=${href} title=${title}`)
    }
  }

  return (
    <a
      aria-label={ariaLabel}
      className={className}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      href={href}
      rel='noopener noreferrer'
      target='_blank'
      title={title}
    >
      {children}
    </a>
  )
}

export default OutboundLink
