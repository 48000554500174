import { NameFilterRowType } from '@/types/filters'

import { FiltersActions } from './actions'
import * as Filters from './actions/types'
import { getInitialNameRow, getInitialCountRow, initialFiltersState } from './initialState'

const filters = (state = initialFiltersState, action: FiltersActions) => {
  switch (action.type) {
    case Filters.INIT_FILTER_FORM:
      return {
        ...initialFiltersState,
        countFilters: action.payload.countForm || initialFiltersState.countFilters,
        nameFilters: action.payload.nameForm || initialFiltersState.nameFilters,
      }
    case Filters.ADD_FILTER: {
      if (action.payload.type === 'name') {
        // Name filter
        // If the name filter exists, replace it
        const filter = action.payload.filter as NameFilterRowType
        const nameFilters = state.nameFilters.filter((row) => row.name[0] !== filter.name[0])
        return {
          ...state,
          nameFilters: [...nameFilters, filter],
          nameForm: getInitialNameRow(),
        }
      } else if (action.payload.type === 'count') {
        // Count filter
        return {
          ...state,
          countFilters: [...state.countFilters, action.payload.filter],
          countForm: getInitialCountRow(),
        }
      } else {
        console.warn('Unknown filter type')
        return state
      }
    }
    case Filters.REMOVE_FILTER: {
      const filterKey = action.payload?.type === 'count' ? 'countFilters' : 'nameFilters'
      return {
        ...state,
        [filterKey]: state[filterKey].filter((row) => row.key !== action.payload.key),
      }
    }
    case Filters.RESET: {
      return initialFiltersState
    }
    case Filters.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case Filters.SET_FILTER_FORM: {
      const formKey = action.payload?.type === 'count' ? 'countForm' : 'nameForm'
      return {
        ...state,
        [formKey]: { ...state[formKey], ...action.payload.filter },
      }
    }
    default:
      return state
  }
}

export default filters
