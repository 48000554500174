import BSSpinner from 'react-bootstrap/Spinner'

type Props = {
  className?: string
  size?: string
}

const Spinner = ({ className, size }: Props) => {
  // @ts-ignore
  return <BSSpinner className={className} animation='border' size={size || 'sm'} />
}

export default Spinner
