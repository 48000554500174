import CookieService from '@/services/cookie.service'
import LocalStorageService from '@/services/localStorage.service'

import { UserActions } from './actions'
import * as User from './actions/types'
import { getUserInitialState } from './initialState'

const reducer = (state = getUserInitialState(), action: UserActions) => {
  switch (action.type) {
    case User.SET_USER_STATE: {
      const newState = { ...state, ...action.payload }
      CookieService.setJSON('userState', {
        displayName: newState.displayName,
        uid: newState.uid,
        email: newState.email,
        isAdmin: newState.isAdmin,
        isStaff: newState.isStaff,
        priceSources: newState.priceSources,
        region: newState.region,
        version: newState.version,
        patreon: newState.patreon,
      })
      LocalStorageService.setJSON('userState', {
        alternateArt: newState.alternateArt,
      })
      return newState
    }
    case User.SIGN_OUT:
      CookieService.remove('userState')
      LocalStorageService.remove('userState')
      return getUserInitialState()
    default:
      return state
  }
}

export default reducer
