import { EDHRECIconsType } from '@/components/Icon/Icon'

export const BORDER_WIDTH = 1
export const CARD_WIDTH = 244
export const GRID_GAP = 8
export const TIMESPANS = [undefined, 'week', 'month', 'year']
export const COLORS = {
  b: 'Mono-Black',
  bg: 'Golgari',
  bgu: 'Sultai',
  br: 'Rakdos',
  brg: 'Jund',
  brgw: 'Dune-Brood',
  c: 'Colorless',
  colorless: 'Colorless',
  g: 'Mono-Green',
  gu: 'Simic',
  gur: 'Temur',
  gw: 'Selesnya',
  gwu: 'Bant',
  gwub: 'Witch-Maw',
  r: 'Mono-Red',
  rg: 'Gruul',
  rgw: 'Naya',
  rgwu: 'Ink-Treader',
  rw: 'Boros',
  rwb: 'Mardu',
  u: 'Mono-Blue',
  ub: 'Dimir',
  ubr: 'Grixis',
  ubrg: 'Glint-Eye',
  ur: 'Izzet',
  urw: 'Jeskai',
  w: 'Mono-White',
  wb: 'Orzhov',
  wbg: 'Abzan',
  wu: 'Azorius',
  wub: 'Esper',
  wubr: 'Yore-Tiller',
  wubrg: 'Five-Color',
}

// https://www.gdpradvisor.co.uk/gdpr-countries
export const gdprAffectedCountries = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'UK',
  // Non-GDPR but similar laws
  'CH',
  'BH',
  'IS',
  'QA',
  'TR',
  'KE',
  'MU',
  'NG',
  'ZA',
  'UG',
  'JP',
  'KR',
  'NZ',
  'AR',
  'BR',
  'UY',
  'CA',
]

export const desktopWidth = 1024

export type Mode = {
  icon?: EDHRECIconsType
  key: string
  skipFetch?: boolean
  value: string
}

export const defaultGroupedBy = 'default'

export const defaultSortedBy = 'default'

export const groupByModes: Mode[] = [
  { icon: 'tableList', key: 'default', skipFetch: true, value: 'Category' },
  { icon: 'tag', key: 'type', value: 'Type' },
  { icon: 'arrowDown19', key: 'mana', value: 'Mana Value' },
  { icon: 'dollarSign', key: 'price', value: 'Price' },
  { icon: 'star', key: 'rarity', value: 'Rarity' },
  { icon: 'calendar', key: 'date', value: 'Release date' },
  { icon: 'tableCellsLarge', key: 'ungrouped', skipFetch: true, value: 'Ungrouped' },
]

export const recaptchaKey = '6Ld7ckoqAAAAACdJC0eF5Ak7de764U3u6RS4BtYi'

export const sortByModes: Mode[] = [
  { key: 'default', skipFetch: true, value: 'Default' },
  { icon: 'percent', key: 'percent', skipFetch: true, value: 'Inclusion' },
  { icon: 'hashtag', key: 'count', skipFetch: true, value: 'Deck Count' },
  { icon: 'wandMagicSparkles', key: 'synergy', skipFetch: true, value: 'Synergy' },
  { icon: 'font', key: 'name', skipFetch: true, value: 'Name' },
  { icon: 'arrowDown19', key: 'mana', value: 'Mana Value' },
  { icon: 'dollarSign', key: 'price', value: 'Price' },
  { key: 'salt', value: 'Salt' },
]

export const tagMap = {
  defender: 'defenders',
  draw: 'card-draw',
  'eldrazi-tokens': 'eldrazi',
  'elemental-tokens': 'elementals',
  'elf-tokens': 'elves',
  enchantments: 'enchantress',
  'energy-counters': 'energy',
  'goblin-tokens': 'goblins',
  'golem-tokens': 'golems',
  'human-tokens': 'humans',
  'insect-tokens': 'insects',
  'knight-tokens': 'knights',
  lands: 'lands-matter',
  'm1-m1-counters': 'minus-1-minus-1-counters',
  'p1-p1-counters': 'plus-1-plus-1-counters',
  pod: 'birthing-pod',
  reanimate: 'reanimator',
  'saproling-tokens': 'saprolings',
  'soldier-tokens': 'soldiers',
  'spirit-tokens': 'spirits',
  'thopter-tokens': 'thopter',
  toughness: 'toughness-matters',
  walls: 'defenders',
  'warrior-tokens': 'warriors',
  'wolf-tokens': 'wolves',
  'zombie-tokens': 'zombies',
}
