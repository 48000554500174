export type ToastType = {
  key: string
  show: boolean
  text: string
}

export type ToastStateType = {
  toasts: ToastType[]
}

export const initialToastState: ToastStateType = {
  toasts: [],
}
