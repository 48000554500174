import Cookies, { CookieSetOptions } from 'universal-cookie'

// Doesn't work
const get = <T = any>(path: string, serverCookies?: Record<string, any> | string): T => {
  const cookies = new Cookies(serverCookies)
  const result = cookies.get(path)
  return result as T
}

// Doesn't work
const getJSON = <T = any>(path: string, serverCookies?: Record<string, any> | string): T =>
  JSON.parse(get(path, serverCookies)) as T

const set = (key: string, value: any, options?: CookieSetOptions) => {
  const cookies = new Cookies()
  cookies.set(key, value, { path: '/', httpOnly: false, ...options })
}

const setJSON = (key: string, value: Record<string, any>, options?: CookieSetOptions) =>
  set(key, JSON.stringify(value), options)

const remove = (key: string) => {
  const cookies = new Cookies()
  cookies.remove(key, { path: '/' })
}

const cookieService = {
  get,
  getJSON,
  remove,
  set,
  setJSON,
}

export default cookieService
