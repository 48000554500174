import Form from 'react-bootstrap/Form'

import Icon from '@/components/Icon/Icon'
import { useTheme } from '@/components/ThemeProvider/ThemeProvider'

const DarkModeSlider = () => {
  const [theme, setTheme] = useTheme()
  const isDarkMode = theme === 'dark'
  return (
    <Form.Switch
      id='dark-mode'
      checked={isDarkMode}
      label={<Icon icon={isDarkMode ? 'moon' : 'sun'} />}
      onChange={() => setTheme(isDarkMode ? 'light' : 'dark')}
    />
  )
}

export default DarkModeSlider
