import { Cookies } from 'react-cookie'

import cookieService from '@/services/cookie.service'
import LocalStorageService from '@/services/localStorage.service'
import { PriceMapType } from '@/types/card'

export const initialPriceSources = ['cardkingdom', 'tcgplayer', 'scg'] as Array<keyof PriceMapType>

export type PatreonObjectType = {
  currently_entitled_amount_cents: number
  full_name: string
  next_charge_date: string
  patron_status: string
}

export type UserStateType = {
  alternateArt?: Record<string, string[]>
  displayName: string
  email: string
  isAdmin?: boolean
  isStaff?: boolean
  namesUnderCards?: boolean
  patreon?: PatreonObjectType
  priceSources: Array<keyof PriceMapType>
  region?: string
  saltLevel?: number
  uid: string
  version: number
}

export const getUserInitialState = (initialCookies?: Cookies): UserStateType => ({
  alternateArt: LocalStorageService.getJSON('userState')?.alternateArt || null,
  displayName: '',
  email: '',
  isAdmin: false,
  isStaff: false,
  namesUnderCards: false,
  patreon: undefined,
  priceSources: initialPriceSources,
  region: undefined,
  saltLevel: undefined,
  uid: '',
  version: 3,
  ...getUserStateCookie(initialCookies),
})

const getUserStateCookie = (initialCookies?: Cookies): Partial<UserStateType> => {
  try {
    // If no cookies are provided, return an empty object
    if (!initialCookies?.get('userState')) return {}

    // Sets 3rd price source to 'scg' under certain conditions (old cookie, default-ish price sources, not CA or MX)
    const { priceSources, region, version, ...rest } = initialCookies.get('userState')
    if (
      version === 2 &&
      ((priceSources[0] === 'cardkingdom' && priceSources[1] === 'tcgplayer') ||
        (priceSources[0] === 'tcgplayer' && priceSources[1] === 'cardkingdom')) &&
      ['cardhoarder', 'face2face'].includes(priceSources[2]) &&
      !['CA', 'MX'].includes(region)
    ) {
      const newUserStateCookie = {
        ...rest,
        priceSources: initialPriceSources,
        region,
        version: 3,
      }
      cookieService.setJSON('userState', newUserStateCookie)
      return newUserStateCookie
    } else {
      return initialCookies.get('userState')
    }
  } catch (e) {
    return {}
  }
}
