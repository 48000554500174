import { CardListsActions } from './actions'
import * as CardLists from './actions/types'
import { initialCardListsState } from './initialState'

const reducer = (state = initialCardListsState, action: CardListsActions) => {
  switch (action.type) {
    case CardLists.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case CardLists.TOGGLE_PERSIST:
      return {
        ...state,
        persist: !state.persist,
      }
    default:
      return state
  }
}

export default reducer
