import { Fragment, useEffect, useRef } from 'react'
import usePrevious from 'react-use/lib/usePrevious'
import { v4 } from 'uuid'

import ClipboardItem from '@/components/Clipboard/ClipboardItem/ClipboardItem'
import { selectCommanderCards, selectOrganizedCards } from '@/store/clipboard/selectors'
import { useAppSelector } from '@/store/hooks'

const ClipboardItems = () => {
  const recsRef = useRef<HTMLDivElement>(null)
  const lists = useAppSelector(selectOrganizedCards)
  const error = useAppSelector((state) => state.clipboard.error)
  const recs = useAppSelector((state) => state.clipboard.recs)
  const commanders = useAppSelector(selectCommanderCards)
  const commanderColorId =
    commanders.length === 0
      ? ['W', 'U', 'B', 'R', 'G']
      : ['W', 'U', 'B', 'R', 'G'].filter((c) =>
          commanders
            .map((commander) => commander?.color_identity)
            .flat()
            .includes(c),
        )
  const prevRecs = usePrevious(recs)

  useEffect(() => {
    if (error || (prevRecs?.length === 0 && recs.length > 0)) {
      recsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [error, recs])

  return (
    <div className='d-flex flex-column justify-content-between h-100 w-100'>
      <div className='d-flex flex-wrap'>
        {commanders?.length > 0 && (
          <Fragment key={v4()}>
            <div className='w-100 text-start' key='commander'>
              {commanders.length > 1 ? 'Commanders' : 'Commander'}
            </div>
            {commanders
              .filter((commander) => commander)
              .map((commander) => (
                <ClipboardItem card={commander} key={commander.name} legal type='commander' />
              ))}
          </Fragment>
        )}
        {Object.values(lists)
          .filter((list) => list.cards.length > 0)
          .sort((a, b) => a.i - b.i)
          .map((list) => {
            list.cards.sort((a, b) => a.cmc - b.cmc)
            return (
              <Fragment key={list.plural}>
                <div className='w-100 text-start'>{`${list.plural} (${list.cards.length})`}</div>
                {list.cards.map((card) => (
                  <ClipboardItem
                    card={card}
                    key={card.name}
                    legal={
                      !card.color_identity ||
                      card.color_identity.filter((c: string) => !commanderColorId.includes(c)).length === 0
                    }
                    type='card'
                  />
                ))}
              </Fragment>
            )
          })}
        {!error && recs.length > 0 && (
          <Fragment key={v4()}>
            <div className='w-100 text-start' key='recs' ref={recsRef}>
              Recs
            </div>
            {recs.map((card, recsIndex) => (
              <ClipboardItem card={card} key={card.name} legal recsIndex={recsIndex} type='rec' />
            ))}
          </Fragment>
        )}
      </div>
      {error && (
        <div className='d-none d-sm-block mt-2 w-100' ref={recsRef}>
          <div className='alert alert-danger' role='alert'>
            {error}
          </div>
        </div>
      )}
    </div>
  )
}
export default ClipboardItems
