import { createSelector } from 'reselect'

import { RootState } from '@/store/reducers'
import { CardType } from '@/types/card'
import { bytesToBase64, tcgplayerLink } from '@/utils/helpers'

export const selectAllClipboardCardNames = createSelector(
  (state: RootState) => state.clipboard.cards,
  (state: RootState) => state.clipboard.commanders,
  (cards, commanders) => {
    return Object.keys(cards).concat(commanders)
  },
)

export const selectOrganizedCards = createSelector(
  (state: RootState) => state.clipboard.cards,
  (state: RootState) => state.clipboard.loadedCards,
  (clipboardCards, cards) => {
    const lists: Record<string, { cards: CardType[]; i: number; plural: string }> = {
      Artifact: { cards: [], i: 3, plural: 'Artifacts' },
      Battle: { cards: [], i: 5, plural: 'Battles' },
      Creature: { cards: [], i: 0, plural: 'Creatures' },
      Enchantment: { cards: [], i: 4, plural: 'Enchantments' },
      Instant: { cards: [], i: 1, plural: 'Instants' },
      Land: { cards: [], i: 7, plural: 'Lands' },
      Planeswalker: { cards: [], i: 6, plural: 'Planeswalkers' },
      Sorcery: { cards: [], i: 2, plural: 'Sorceries' },
    }
    Object.keys(clipboardCards)
      .map((name) => cards[name])
      .filter((x) => x)
      .forEach((card) => lists[card.primary_type]?.cards.push(card))
    return lists
  },
)

export const selectAllClipboardCards = createSelector(
  (state: RootState) => state.clipboard.cards,
  (state: RootState) => state.clipboard.commanders,
  (state: RootState) => state.clipboard.loadedCards,
  (clipboardCards, commanders, cards = {}) => {
    const allCards = Object.fromEntries(Object.keys(clipboardCards).map((url) => [url, cards[url]]))
    commanders.forEach((url) => {
      allCards[url] = cards[url]
    })
    for (const [url, card] of Object.entries(allCards)) {
      if (!card) {
        delete allCards[url]
      }
    }
    return allCards
  },
)

export const selectClipboardPrices = createSelector(selectAllClipboardCards, (allCards) => {
  const cardkingdom = Object.values(allCards).reduce((acc, card) => {
    if (card?.prices?.cardkingdom?.price) {
      acc += card.prices.cardkingdom.price
    }
    return acc
  }, 0)
  const scg = Object.values(allCards).reduce((acc, card) => {
    if (card?.prices?.scg?.price) {
      acc += card.prices.scg.price
    }
    return acc
  }, 0)
  const manapool = Object.values(allCards).reduce((acc, card) => {
    if (card?.prices?.manapool?.price) {
      acc += card.prices.manapool.price
    }
    return acc
  }, 0)
  const tcgplayer = Object.values(allCards).reduce((acc, card) => {
    if (card?.prices?.tcgplayer?.price) {
      acc += card.prices.tcgplayer.price
    }
    return acc
  }, 0)
  const tcgl = Object.values(allCards).reduce((acc, card) => {
    if (card?.prices?.tcgl?.price) {
      acc += card.prices.tcgl.price
    }
    return acc
  }, 0)
  return { cardkingdom, manapool, scg, tcgl, tcgplayer }
})

export const selectIsEmpty = createSelector(
  (state: RootState) => state.clipboard.cards,
  (state: RootState) => state.clipboard.commanders,
  (clipboardCards, commanders) => {
    return Object.keys(clipboardCards).length === 0 && commanders.length === 0
  },
)

export const selectClipboardSize = createSelector(selectAllClipboardCards, (allCards) => Object.keys(allCards).length)

const getDecklistParams = (cards: CardType[], delimiter: string, quantity = true) => {
  const decklistString = cards
    .map((card) => {
      if (!card.name.includes(' // ') || card.layout === 'split') return card.name
      return card.name.substring(0, card.name.indexOf(' // ')) // Remove transformed title text if it exists
    })
    .map((card) => (quantity ? '1 ' : '') + card)
    .join(delimiter)
  return decklistString
}

export const selectExportUrls = createSelector(selectAllClipboardCards, (cards) => {
  const pipeList = getDecklistParams(Object.values(cards), '|', false)
  const doublePipeList = getDecklistParams(Object.values(cards), '||')
  const newlineList = getDecklistParams(Object.values(cards), '\n')

  const archidekt = `https://archidekt.com/cardImport?c=${encodeURIComponent(newlineList)}`
  const ck = `https://www.cardkingdom.com/builder?partner=edhrec&utm_source=edhrec&utm_medium=clipboard&utm_campaign=edhrec&partner_args=edhrec,clipboard&c=${encodeURIComponent(newlineList)}`
  const manapool = `https://manapool.com/add-deck?ref=scm&tap_s=5258585-0b68aa&tm_medium=edhrec,cardprice&deck=${bytesToBase64(newlineList).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')}`
  const moxfield = `https://www.moxfield.com/import?c=${encodeURIComponent(newlineList)}`
  const tcg = tcgplayerLink({
    subId1: 'clipboard',
    u: `https://store.tcgplayer.com/massentry?partner=EDHREC&utm_campaign=affiliate&utm_medium=clipboard&utm_source=EDHREC&partner_args=clipboard&c=${encodeURIComponent(doublePipeList)}`,
  })
  const tcgl = `https://www.tcg.land/multisearch#/magic-the-gathering?format=only_names&separator=|&lines=${encodeURIComponent(pipeList)}`

  return { archidekt, ck, manapool, moxfield, tcg, tcgl }
})

export const selectCommanderCards = createSelector(
  (state: RootState) => state.clipboard.commanders,
  (state: RootState) => state.clipboard.loadedCards,
  (commanders, cards) => {
    return commanders.map((url) => cards[url]).filter((x) => x)
  },
)

export const selectClipboardIncludesCard = (card: CardType) =>
  createSelector(selectAllClipboardCardNames, (clipboardCardNames) => {
    const keys = card.cards ? card.cards.map((c) => c.url) : card.sanitized_wo ? [card.sanitized_wo] : [card.url]
    return keys.every((key) => clipboardCardNames.includes(key as string))
  })
