import classNames from 'classnames'
import Link from 'next/link'

import Icon from '@/components/Icon/Icon'
import OutboundLink from '@/components/OutboundLink/OutboundLink'

import styles from './Footer.module.scss'

const Footer = () => (
  <div className={styles.container}>
    <div className={styles.containerInner}>
      <div className={classNames('d-sm-none d-md-flex', styles.row, styles.vertical)}>
        <Link href='/about-us'>About Us</Link>
        <Link href='/contact-us'>Contact</Link>
        <Link href='/privacy'>Privacy Policy</Link>
        <Link href='/terms'>Terms of Service</Link>
      </div>
      <div className={styles.row}>
        <OutboundLink ariaLabel='Facebook' url='https://www.facebook.com/EDHREC1' utm_medium='footer'>
          <Icon icon='facebook' />
        </OutboundLink>
        <OutboundLink ariaLabel='Instagram' url='https://www.instagram.com/EDHREC' utm_medium='footer'>
          <Icon icon='instagram' />
        </OutboundLink>
        <OutboundLink ariaLabel='RSS' url='https://edhrec.com/articles/feed/?cat=4' utm_medium='footer'>
          <Icon icon='rss' />
        </OutboundLink>
        <OutboundLink ariaLabel='TikTok' url='https://www.tiktok.com/@edhrec' utm_medium='footer'>
          <Icon icon='tiktok' />
        </OutboundLink>
        <OutboundLink ariaLabel='Twitch' url='https://www.twitch.tv/EDHRECast' utm_medium='footer'>
          <Icon icon='twitch' />
        </OutboundLink>
        <OutboundLink ariaLabel='Twitter' url='https://www.twitter.com/EDHREC' utm_medium='footer'>
          <Icon icon='twitter' />
        </OutboundLink>
        <OutboundLink ariaLabel='YouTube' url='https://www.youtube.com/EDHRECast' utm_medium='footer'>
          <Icon icon='youtube' />
        </OutboundLink>
      </div>
      <div className={classNames('d-sm-none d-md-flex', styles.row, styles.vertical)}>
        <OutboundLink className={styles.scm} url='https://spacecowmedia.com/' utm_medium='footer'>
          Space Cow Media
        </OutboundLink>
        <OutboundLink className='d-none d-md-flex' url='https://archidekt.com/' utm_medium='footer'>
          Archidekt
        </OutboundLink>
        <OutboundLink className='d-none d-md-flex' url='https://cardsphere.com/' utm_medium='footer'>
          Cardsphere
        </OutboundLink>
        <OutboundLink className='d-none d-md-flex' url='https://commandersherald.com/' utm_medium='footer'>
          Commander&apos;s Herald
        </OutboundLink>
        <OutboundLink className='d-none d-md-flex' url='https://commanderspellbook.com/' utm_medium='footer'>
          Commander Spellbook
        </OutboundLink>
        <OutboundLink className='d-none d-md-flex' url='https://fabrec.gg/' utm_medium='footer'>
          FABREC
        </OutboundLink>
        <OutboundLink className='d-none d-md-flex' url='https://spellvoid.com/' utm_medium='footer'>
          Spellvoid
        </OutboundLink>
      </div>
      <div className={classNames('d-none d-sm-flex d-md-none', styles.row)}>
        <OutboundLink className={styles.scm} url='https://spacecowmedia.com/' utm_medium='footer'>
          Space Cow Media
        </OutboundLink>
        <OutboundLink url='https://archidekt.com/' utm_medium='footer'>
          Archidekt
        </OutboundLink>
        <OutboundLink url='https://commandersherald.com/' utm_medium='footer'>
          Commander&apos;s Herald
        </OutboundLink>
      </div>
      <div className={classNames('d-none d-sm-flex d-md-none', styles.row)}>
        <OutboundLink url='https://commanderspellbook.com/' utm_medium='footer'>
          Commander Spellbook
        </OutboundLink>
        <OutboundLink url='https://fabrec.gg/' utm_medium='footer'>
          FABREC
        </OutboundLink>
        <OutboundLink url='https://spellvoid.com/' utm_medium='footer'>
          Spellvoid
        </OutboundLink>
      </div>
      <div className={styles.row}>
        <div className={styles.wotc}>
          EDHREC is unofficial Fan Content permitted under the{' '}
          <OutboundLink url='https://company.wizards.com/en/legal/fancontentpolicy'>Fan Content Policy</OutboundLink>.
          Not approved/endorsed by Wizards. Portions of the materials used are property of Wizards of the Coast.
          ©Wizards of the Coast LLC.
        </div>
      </div>
    </div>
  </div>
)

export default Footer
